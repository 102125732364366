<template>
  <div class="accountset">
    <userheader menucurrent="2" />
    <div class="main-div">
      <div class="account-content wrap">
        <!-- 左侧导航 -->
        <userleft currindex="8" ref="userleft" />

        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="title">企业名片管理<em></em></h3>
              <div class="info-cnt">
                <div class="content-rb">
                  <el-row :gutter="20" align="middle">
                    <el-col :span="16" :offset="5">
                      <el-card shadow="always" v-if="userinfo != null">
                        <div class="info-top">
                          <el-avatar
                            shape="circle"
                            :size="80"
                            :src="userAvatar"
                          ></el-avatar>
                          <h2
                            style="
                              margin: 0;
                              font-size: 1.2rem;
                              line-height: 3rem;
                            "
                          >
                            {{ userNickName }}
                            <span
                              href="javascript:;"
                              @click="comqrcodedialogVisible = true"
                              class="
                                comqrcode
                                icon
                                icon-zujian-icon-18
                              "
                            >
                            </span>
                          </h2>
                        </div>
                        <div class="info-bottom">
                          <div>
                            <table class="border">
                              <tbody>
                                <tr v-if="userinfo.iDcardName != null">
                                  <td class="text">姓名</td>
                                  <td class="text">
                                    {{ userinfo.iDcardName }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text">手机号</td>
                                  <td class="text">
                                    {{ userinfo.userMobile }}
                                  </td>
                                </tr>
                                <!-- <tr v-if="userinfo.iDcardSex!=null&&userinfo.iDcardSex!=''">
                                  <td class="text">性别</td>
                                  <td class="text">{{userinfo.iDcardSex}}</td>
                                </tr>
                                <tr v-if="userinfo.iDcardNation!=null&&userinfo.iDcardNation!=''">
                                  <td class="text">民族</td>
                                  <td class="text">{{userinfo.iDcardNation}}</td>
                                </tr> -->
                                <tr v-if="titleList.length > 0">
                                  <td class="text">职称</td>
                                  <td class="text pos">
                                    <el-tag
                                      type="success"
                                      v-for="item in titleList"
                                      v-bind:key="item"
                                      >{{ item }}</el-tag
                                    >
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </el-card>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="16" :offset="5">
                      <div class="">
                        <el-collapse>
                          <el-collapse-item
                            :name="item.companyId"
                            v-model="activeName"
                            v-for="(item, index) in companyPostList"
                            v-bind:key="index"
                          >
                            <template slot="title">
                              <b class="prefix">企</b> {{ item.companyName }}
                              <div @click="stopProp">
                                <el-switch
                                  v-model="item.isShowInComQRCode"
                                  active-color="#13ce66"
                                  inactive-color="#ff4949"
                                  :active-value="1"
                                  :inactive-value="0"
                                  active-text="显示"
                                  inactive-text="隐藏"
                                  @change="
                                    showcompany(
                                      item.companyId,
                                      item.isShowInComQRCode,
                                      index
                                    )
                                  "
                                >
                                </el-switch>
                              </div>
                            </template>
                            <div>
                              <div
                                class="tip"
                                v-for="dpt in item.dptList"
                                v-bind:key="dpt.id"
                              >
                                <p>
                                  {{ dpt.userDptName }}
                                  <el-tag
                                    type="success"
                                    v-for="post in dpt.postList"
                                    class="post"
                                    v-bind:key="post.id"
                                  >
                                    {{ post.userPostName }}</el-tag
                                  >
                                </p>
                              </div>
                            </div>
                          </el-collapse-item>
                        </el-collapse>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <userfooter />
    <el-dialog
      :visible.sync="comqrcodedialogVisible"
      class="qrcodebig"
      center
      title="企业名片二维码"
      top="25vh"
      width="350px"
      :before-close="handlecomqrcode"
    >
      <el-row>
        <el-col :span="24">
          <el-row>
            <el-col :span="24">
              <div
                class="grid-content bg-purple-dark"
                style="padding-left: 15px"
              >
                <div>
                  <div class="avatarbox">
                    <div class="avar f-l">
                      <el-avatar
                        shape="circle"
                        :size="60"
                        :src="userAvatar"
                      ></el-avatar>
                    </div>
                  </div>
                  <div class="namebox">
                    <span>{{ userNickName }} </span>
                    <!-- <span style="font-size:14px;line-height:20px;display:inline-block;padding:5px 0;">企业管二维码</span> -->
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="grid-content bigqrcode">
                <img
                  class="main-nav__qrcodebig__img"
                  :src="bigcomqrcodeurl"
                  width="200"
                  height="200"
                />
              </div>
            </el-col>
          </el-row>
          <el-row v-if="false">
            <el-col :span="24">
              <div class="grid-content qrcode-tips">扫一扫上面的二维码图案</div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import Cookies from 'js-cookie';
import { getCookieByName } from '@/utils/helper';
var token = getCookieByName('access_token');
import {
  usergetcurruserinfo,
  getuserinfobydesuid,
  getuserpostandtitle,
  utilsgetuserpostandtitleqrcodebase64,
} from '@/api/user';
import { setcompanyshowincomqrcode } from '@/api/usersetting';
import userleft from '@/components/userleft.vue';
export default {
  name: 'App',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
  },
  data() {
    const self = this;
    return {
      showstate: false,
      bigcomqrcodeurl: '',
      userAvatar: require('@/assets/img/defaultheadpic.png'),
      comqrcodedialogVisible: false,
      bigqrcodeurl: '',
      dialogImageUrl: '',
      dialogVisible: false,
      firstShow: true,
      show: false,
      userinfo: null,
      postandinfo: null,
      titleList: [],
      companyPostList: null,
      userNickName: '',
      activeName: '1',
    };
  },
  methods: {
    onhide() {
      alert(11);
    },
    stopProp(e) {
      e.stopPropagation();
    },
    //显示或者隐藏公司
    showcompany(id, val, index) {
      setcompanyshowincomqrcode({ companyId: id, isShow: val }).then((res) => {
        if (res.data.code === 1) {
          this.$message.success('操作成功');
        } else {
          this.$message.error(res.data.message);
          if (val === 1) {
            this.companyPostList[index].isShowInComQRCode = 0;
          } else {
            this.companyPostList[index].isShowInComQRCode = 1;
          }
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    onuserleft() {
      this.$refs.userleft.handleqrcode();
    },
    handlecomqrcode() {
      this.comqrcodedialogVisible = false;
    },
    ongetuserpostandtitle() {
      let params = { desUId: getCookieByName('user_id') };
      getuserpostandtitle(params).then((res) => {
        if (res.data.result !== null) {
          this.companyPostList = res.data.result.companyPostList;
          this.titleList = res.data.result.titleList;
        }
      });
    },
    onutilsgetuserpostandtitleqrcodebase64() {
      let params = { desUId: getCookieByName('user_id') };
      utilsgetuserpostandtitleqrcodebase64(params).then((res) => {
        if (res.data.result !== null) {
          this.bigcomqrcodeurl = 'data:image/jpeg;base64,' + res.data.result;
        }
      });
    },
  },
  created() {
    var that = this;
    usergetcurruserinfo().then((res) => {
      if (res.data.code == 1) {
        this.userinfo = res.data.result;
        if (that.userinfo.userNickName) {
          that.userNickName = this.userinfo.userNickName;
        } else {
          that.userNickName = this.userinfo.userMobile;
        }
      }
    });
    this.ongetuserpostandtitle();
    this.onutilsgetuserpostandtitleqrcodebase64();
  },
  mounted() {},
};
</script>
<style  scoped >
/**公共css开始**/
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.acount-card {
  background-position: 0 -172px;
  background-image: url('../../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 940px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}

.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
}
.accountset >>> form.elform {
  width: 460px;
}
.main-nav__qrcode__img {
  position: relative;
  top: 2px;
  margin-left: 5px;
}
/**公共css结束**/
/* .accountset >>> form.elform .formaddress .el-input {
  width: 220px;
  margin-right: 10px;
} */

.content-rb table {
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
  margin-bottom: 0px;
  line-height: 1.5em;
}
.content-rb table tr:first-child td {
  border-top: 1px solid #e8e8e8;
}
.content-rb table td {
  color: #606266;
}

.content-rb table td,
.content-rb table th {
  border-bottom: 1px solid #e8e8e8;
  padding: 15px;
  max-width: 250px;
}
.content-rb table td:first-child {
  width: 140px;
}
.content-rb >>> .el-avatar > img {
  width: 100%;
}
.content-rb .info-top {
  text-align: center;
  padding: 20px 0;
}
.content-rb >>> .el-card {
  background-color: #fbfbfb;
}
.content-rb .tip {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin: 5px 0 10px;
}
.content-rb >>> .el-collapse-item__content {
  padding-bottom: 0px;
}
.content-rb >>> .el-collapse-item__header {
  font-weight: 600;
  font-size: 17px;
  height: 60px;
  line-height: 60px;
  color: #484848;
  padding-left: 5px;
  position: relative;
}
.content-rb >>> .el-tag {
  margin: 0 5px;
  height: 24px;
  line-height: 24px;
  padding: 0 4px;
}
.content-rb table td.pos {
  padding-left: 7px;
}
.content-rb .prefix {
  display: inline-block;
  /* margin-top: 40px; */
  /* margin-left: -20px; */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: #ffffff;
  background: #1989fa;
  text-align: center;
  font-size: 14px;
  top: -1px;
  left: -3px;
  position: relative;
}
.qrcodebig .avatarbox {
  width: 70px;
  float: left;
}
.qrcodebig >>> .el-avatar > img {
  width: 100%;
}
.qrcodebig .namebox {
  float: left;
}
.qrcodebig .namebox span {
  display: inline-block;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  top: 10px;
}
.bigqrcode {
  text-align: center;
  padding: 20px 0;
}
.qrcode-tips {
  text-align: center;
  font-size: 14px;
  color: #9c9797;
  margin-top: 5px;
}
.el-switch {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  height: 0;
  vertical-align: middle;
  float: right;
  text-align: right;
  position: absolute;
  right: 34px;
}
.comqrcode{
  cursor: pointer;
}
</style>
